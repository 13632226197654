import * as React from "react";
import CycleSection from "./Homepage/HomeSections/Cycle";
import BannerHeader from "../../layouts/global/BannerHeader";
import aboutUs from "../../assets/images/aboutUs.jpg";
export default function Cycle() {
  return (
    <div>
      <BannerHeader title="Our Cycle" subTitle="Always with us" url={aboutUs} />
      <CycleSection />
    </div>
  );
}
