import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./MissionFuture.css";
import Mission from "../../../../assets/images/construction.jpg";
import mapBackground from "../../../../assets/images/map.png";

export default function MissionFuture() {
  return (
    <Container sx={{ paddingTop: "150px" }}>
      <Grid
        container
        className="about-section"
        style={{ backgroundImage: `url(${mapBackground})` }}
      >
        <Grid item xs={12} lg={6} order={1}>
          <div className="reveal">
            <Typography className="pre-title">Mission of AWU</Typography>

            <Typography component="h1" variant="h2" align="start" gutterBottom>
              MISSION of <br />
              AWU Foundation
            </Typography>
            <Typography component="p" sx={{ pb: "10px" }}>
              The AWU Foundation aims to help these families by providing them
              with a stable place to live, addressing their inability to secure
              proper housing. By doing so, the foundation intends to facilitate
              their integration into society. Additionally, it seeks to prevent
              various crimes related to livelihood and promote the growth of the
              middle class, which is crucial for a healthier and more balanced
              society. Through these efforts, the foundation aims to alleviate
              some of the class conflicts in society and contribute to its
              overall well-being.
            </Typography>
            <Typography component="p" sx={{ pb: "10px" }}>
              In many countries, the number of poor people exceeds 50% of the
              total population. These individuals face numerous challenges as a
              result of conflicts between the rich and the poor, and they
              struggle desperately to survive each day. They lack essential
              necessities, which disrupts the foundation of family and leads to
              serious social problems.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} order={2}>
          <div className="image-container1 reveal">
            <img src={Mission} alt="Family Image" className="mission-image" />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
