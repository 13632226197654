import React from "react";
import "./Business.css";
import news19 from "../../../../assets/images/business.jpg";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

const BusinessSection = () => {
  return (
    <div className="">
      <div className="text text-center reveal">
        <p className="pre-title-center reveal">HOMS TOKEN</p>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Business of the AWU Foundation
        </Typography>
      </div>
      <div className="business-section reveal">
        <div className="left-grid">
          <div className="business-cards">
            <h3>Residents</h3>
            <p>
              To become residents, individuals undergo a tenant selection
              process that is carefully reviewed by the foundation's and local
              governments. The foundation aims to alleviate the financial burden
              on poor tenants by setting the rent at less than half the market
              rental price.
            </p>
          </div>
        </div>
        <div className="middle-grid">
          <img src={news19} alt="Business Image" />
        </div>
        <div className="right-grid">
          <div className="business-cards">
            <h3>HOMS Token</h3>
            <p>
              Tenants purchase and pay their rent to the foundation.
              Additionally, tenants are required to save an HOMS token
              equivalent to of the rent. This savings can be accessed in case of
              future emergencies, and it offers the advantage of receiving
              preferential sales when the house is eventually sold.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessSection;
