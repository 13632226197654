import { FmdGood } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

function BenefitsBox({ title, body, direction, icon: Icon }) {
  return (
    <Box className="benefits-box-container">
      <Typography
        className={`benefits-title ${direction !== "left" ? "left" : "right"}`}
      >
        {title}
      </Typography>
      <Box
        className={`divider-container ${
          direction === "left" ? "left" : "right"
        }`}
      >
        <Box className="divider-hr" />
        <Box className="icon-container">
          {Icon ? (
            <Icon className="icon-benefits" />
          ) : (
            <FmdGood className="icon-benefits" />
          )}
        </Box>
      </Box>
      <Typography className="benefits-body">{body}</Typography>
    </Box>
  );
}

export default BenefitsBox;
