import Header from "./Header";
import Footer from "./Footer";
import RocketButton from "./Floating";
import { useLocation } from "react-router-dom";

const AWULayout = (props) => {
  const location = useLocation();

  return (
    <div className="body">
      <div className="top inner_pages top-background">
        {location.pathname !== "/" ? <Header /> : null}
        {location.pathname === "/" ? props.children : null}
        <RocketButton />
      </div>
      {location.pathname !== "/" ? props.children : null}
      {location.pathname !== "/" ? <Footer /> : null}
    </div>
  );
};

export default AWULayout;
