import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import buildingImg from "../../assets/images/country.png";
import familyImage from "../../assets/images/family2.jpg";

function FutureOfAWU() {
  return (
    <Container>
      <Grid container sx={{ pb: 6 }}>
        <Grid item xs={12} lg={6} order={2}>
          <img src={familyImage} />
        </Grid>
        <Grid item xs={12} lg={6} order={1}>
          <div className="reveal">
            <Typography className="pre-title">Future of AWU</Typography>

            <Typography component="h1" variant="h2" align="start" gutterBottom>
              Plans for decentralization management
            </Typography>
            <Typography component="p">
              The AWU Foundation plans to operate with a thoroughly
              decentralized management system to prevent various illegal
              misappropriations and ensure a democratic management structure
              that benefits the community. This involves managing the HOMS token
              through a board of directors composed of NGOs or social
              organizations from more than 5 countries, providing a diverse
              range of perspectives and expertise. The Foundation will continue
              to prioritize.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <div className="reveal">
        <Typography className="pre-title-center">Our Future</Typography>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          justify="center"
          gutterBottom
        >
          Future expansion
        </Typography>
        <Typography component="p">
          The AWU Foundation has plans for future expansion beyond its initial
          rental apartment business. This includes expanding into complex
          welfare projects such as financial services, shopping services, and
          scholarship programs for lower income groups. The foundation also
          plans to diversify the composition of its Board of Trustees to ensure
          democratic management and continue to successfully contribute to the
          well-being of human communities for generations to come. Additionally,
          the foundation plans to leverage emerging technologies such as
          blockchain and NFTs to drive social impact and fundraising efforts.
        </Typography>
      </div>
      <img src={buildingImg} alt="Family" className="building-img" />
    </Container>
  );
}

export default FutureOfAWU;
