import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import buildingImg from "../../assets/images/country.png";
import BannerHeader from "../../layouts/global/BannerHeader";
import board from "../../assets/images/boards.png";
import Yoshida from "../../assets/images/board_members/YoshidaKoji.png";
import Celmer from "../../assets/images/board_members/CelmerSantos.png";
import Renato from "../../assets/images/board_members/RenatoMercadoII.png";
import Guillerma from "../../assets/images/board_members/GuillermoLimboc.png";
import Ferdinand from "../../assets/images/board_members/FerdinandFlorencio.png";
import Jun from "../../assets/images/board_members/JunKawamoto.png";
import Andin from "../../assets/images/board_members/AndzinNazrullahAchmad.png";
import Moon from "../../assets/images/board_members/MoonChanWang.png";
import Hee from "../../assets/images/board_members/Hee-TaeYoung.png";
import YoungTae from "../../assets/images/board_members/YoungTaeKim.png";
import YoungHae from "../../assets/images/board_members/YoungHaeJeong.png";
import YeoChang from "../../assets/images/board_members/YeoChangMo.png";
import JeongHwan from "../../assets/images/board_members/JeongHwanPark.png";
import YoungMan from "../../assets/images/board_members/YoungManKim.png";
import sangIn from "../../assets/images/board_members/SangInChoi.png";
import SungHa from "../../assets/images/board_members/SungHaHwang.png";
import Kim from "../../assets/images/board_members/Kim Sun Tae.png";

const directors = [
  {
    name: "Celmer Santos",
    description: "Trustee",
    description2: "FEU Alumni foundation",
    image: Celmer,
  },
  {
    name: "Renato Mercado II",
    description: "President",
    description2: "Daniel Mercado Sr. Foundation",
    image: Renato,
  },
  {
    name: "Guillerma Limboc",
    description: "President",
    description2: "Business Development",
    image: Guillerma,
  },
  {
    name: "Ferdinand Florencio",
    description: "Senior Manager",
    description2: "Pagcor Marketing Department",
    image: Ferdinand,
  },
  {
    name: "Jun Kawamoto",
    description: "CEO / Tax Accountant",
    description2: "JUN Kawamoto Tax Office",
    image: Jun,
  },
  {
    name: "Yoshida Koji",
    description: "President",
    description2: "Businessman",
    image: Yoshida,
  },
  {
    name: "Andin Nazrullah Achmad",
    description: "Operations Manager",
    description2: "Al-Ihsan Charity Group",
    image: Andin,
  },
  {
    name: "Moon Chan Wang",
    description: "Chairman",
    description2: "Simbok co.,Ltd Chairman",
    image: Moon,
  },
  {
    name: "Hee-Tae Young",
    description: "Chairman",
    description2: "The Korean Professor's Association of F&H",
    image: Hee,
  },
  {
    name: "Young Tae Kim",
    description: "Professor & Journalist",
    description2: "Korea Industrial Field",
    image: YoungTae,
  },
  {
    name: "Young Hae Jeong",
    description: "President",
    description2: "Youngwon Industry Co.,Ltd",
    image: YoungHae,
  },
  {
    name: "Yeo Chang Mo",
    description: "President",
    description2: "GH Human Resources Development",
    image: YeoChang,
  },
  {
    name: "Jeong Hwan Park",
    description: "Chairman",
    description2: "PANAX Co.,Ltd",
    image: JeongHwan,
  },
  {
    name: "Young Man Kim",
    description: "Chairman",
    description2: "GL Bio Techno Co., Ltd",
    image: YoungMan,
  },
  {
    name: "Sang In Choi",
    description: "Director",
    description2: "Korea Oil Energy Co., Ltd",
    image: sangIn,
  },
  {
    name: "Sung Ha Hwang",
    description: "CEO",
    description2: "GE Appliance",
    image: SungHa,
  },
  {
    name: "Kim Sun Tae",
    description: "President",
    description2: "Taejung Corporation",
    image: Kim,
  },
];

export default function Timeline() {
  return (
    <div>
      <BannerHeader
        title="Board of Directors"
        subTitle="Always with us"
        url={board}
      />
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="directors"
      >
        <div className="reveal">
          <div className="text text-center">
            <p className="pre-title-center">Who are involved?</p>
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              Board of Directors
            </Typography>
            <Typography variant="h5" align="center" component="p">
              The AWU Foundation employs a decentralized management approach
              that utilizes a board of directors consisting of 10 to 30 members.
              This board includes Non-Government Organizations (NGOs) from five
              or more countries or a reputable social organization.
            </Typography>
          </div>
        </div>
        <Grid container spacing={2} justifyContent="center" mt={4}>
          {directors.map((director, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <DirectorCard {...director} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <img src={buildingImg} alt="Family" className="building-img" />
    </div>
  );
}

const DirectorCard = ({ name, description, description2, image }) => (
  <div className="reveal" style={{ textAlign: "center" }}>
    <Box
      sx={{
        width: 150,
        height: 150,
        margin: "0 auto",
        borderRadius: "50%",
        overflow: "hidden",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 1)", // Add shadow effect
      }}
    >
      <Avatar alt={name} src={image} sx={{ width: "100%", height: "100%" }} />
    </Box>
    <Typography variant="h6" mt={2}>
      {name}
    </Typography>
    <Typography variant="body1">{description}</Typography>
    <Typography variant="body1">{description2}</Typography>
  </div>
);
