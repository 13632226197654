import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import buildingImg from "../../assets/images/country.png";
import BannerHeader from "../../layouts/global/BannerHeader";
import board from "../../assets/images/boards.png";
import Panelo from "../../assets/images/members/Panelo.png";
import Ernest from "../../assets/images/members/ERnest.png";
import Krystelle from "../../assets/images/members/KrystelleGal.png";
import Kaydee from "../../assets/images/members/Kaydee.png";
import CJ from "../../assets/images/members/CJJaravata.png";
import Robert from "../../assets/images/members/RobertBaesa.png";
import Ramon from "../../assets/images/members/RamonGutz.png";
import Crispin from "../../assets/images/members/CrispinReyes.png";
import Philip from "../../assets/images/members/PhilipSalvador.png";
import RobertY from "../../assets/images/members/RobertYupanco.png";

const directors = {
  "Legal Advisor": [
    {
      name: "Salvador Panelo",
      description: "Former Spokesman and Chief Presidential",
      description2: "Legal Counsel of President Rodrigo Roa Duterte",
      image: Panelo,
    },
    {
      name: "Robert Yupangco",
      description: "Chairman",
      description2: "Zoomanity Foundation",
      image: RobertY,
    },
  ],

  // "ICO Advisors": [
  //   {
  //     name: "Ernest Tan",
  //     description: "Strategic Partnerships, Strategic Expert Consultant",
  //     description2: "Listing Manager, IEO Listing & Advisor",
  //     image: Ernest,
  //   },
  //   {
  //     name: "Krystelle Galano",
  //     description: "Founder, Co-founder",
  //     description2: "CryptoFemale Philippines, Newscall.co",
  //     image: Krystelle,
  //   },
  // ],
  "Global Ambassadors": [
    {
      name: "Kaydee Velasco",
      description: "CEO",
      description2: "VI Group",
      image: Kaydee,
    },
    {
      name: "CJ Aldaba",
      description: "President & Director",
      description2: "Influencers Active Social Philippines",
      image: CJ,
    },
  ],
  Assistants: [
    {
      name: "Robert Baesa",
      description: "Chief of Police",
      description2: "Taguig City Police station",
      image: Robert,
    },
    {
      name: "Ramon Christopher Gutierrez",
      description: "Actor",
      description2: "Talahib at rosas, Felix Manalo",
      image: Ramon,
    },
    {
      name: "Crispin Reyes",
      description: "Brgy Chairman",
      description2: "Brgy. Biluso Silang Cavite",
      image: Crispin,
    },
    {
      name: "Philip Salvador",
      description: "Actor",
      description2: "FIAMAS Best Actor",
      image: Philip,
    },
  ],
};

export default function Timeline() {
  return (
    <div>
      <BannerHeader title="Members" subTitle="Always with us" url={board} />
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="directors"
      >
        <div className="reveal">
          <div className="text text-center">
            <p className="pre-title-center">AWU Foundation</p>
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              Members
            </Typography>
            <Typography variant="h5" align="center" component="p">
              The members of the AWU Foundation play a crucial role in upholding
              the foundation's mission and vision. Their diverse expertise and
              dedication ensure that the foundation's goals are met and its
              impact is felt globally.
            </Typography>
          </div>
        </div>
        {Object.entries(directors).map(([section, members], sectionIndex) => (
          <div key={sectionIndex}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              {section}
            </Typography>
            <Grid container spacing={2} justifyContent="center" mt={4}>
              {members.map((director, index) => (
                <Grid item key={index} xs={12} sm={6} md={3}>
                  <DirectorCard {...director} />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </Container>
      <img src={buildingImg} alt="Family" className="building-img" />
    </div>
  );
}

const DirectorCard = ({ name, description, description2, image }) => (
  <div className="reveal" style={{ textAlign: "center" }}>
    <Box
      sx={{
        width: 150,
        height: 150,
        margin: "0 auto",
        borderRadius: "50%",
        overflow: "hidden",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 1)", // Add shadow effect
      }}
    >
      <Avatar alt={name} src={image} sx={{ width: "100%", height: "100%" }} />
    </Box>
    <Typography variant="h6" mt={2}>
      {name}
    </Typography>
    <Typography variant="body1">{description}</Typography>
    <Typography variant="body1">{description2}</Typography>
  </div>
);
