import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import coins from "../../assets/images/coin.png";
import Dollar from "../../assets/images/Dollar.png";
import FutureBox from "../../components/FutureContainer";
import BannerHeader from "../../layouts/global/BannerHeader";

export default function NFT() {
  return (
    <div className="section nft">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6, marginTop: "10rem" }}
        id="nft"
      >
        <div className="reveal">
          <div className="text text-center">
            <p className="pre-title-center"> AWU NFTs</p>
            <Typography align="center" variant="h2">
              AWU NFTs
            </Typography>
            <Typography align="center">
              NFTs provide a unique opportunity to purchase stakes in order for
              AWU Foundation to build more condominiums or apartments. Value
              would increase over time and more tenants would be able to occupy
              the building. Increased value means better value of the purchased
              NFT.
            </Typography>
          </div>
        </div>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <img src={coins} className="future-building" alt="coins" />
          <Grid
            container
            columns={3}
            sx={{
              marginTop: 5,
            }}
            spacing={5}
          >
            <Grid item xs={3} md={1}>
              <FutureBox
                title="How AWU is leveraging NFTs"
                text="AWU is leveraging NFTs to create unique digital assets that represent physical assets, such as real estate, to raise funds for social impact projects. By selling NFTs, AWU can generate revenue and increase awareness of its mission while promoting the value of digital ownership. Additionally, AWU plans to use NFTs to build more apartments and condominium to accommodate more tenants in the future."
                icon={Dollar}
              />
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={3} md={1}>
              <FutureBox
                variant="right"
                title="How do AWU use NFT?"
                text="The AWU Foundation plans to use NFTs for its affordable housing initiatives, including issuing Property Title NFTs representing partner ownership of rental apartments after 10 years. Its value would increase in proportion with the purchased stakes."
                icon={Dollar}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
