import icoImage from "../../assets/images/icoImage.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TokenIcon from "@mui/icons-material/Token";
import { Container, Grid, Typography } from "@mui/material";

const listItemsData = [
  {
    icon: <LocationOnIcon className="list" />,
    primaryText:
      "The AWU Foundation plans to have the Initial Coin Offering in Singapore on August 2023",
  },
  {
    icon: <AttachMoneyIcon className="list" />,
    primaryText: "With an initial price issuance of $0.2 per token",
  },
  {
    icon: <ApartmentIcon className="list" />,
    primaryText:
      "Market supply of 7,770,000,000 units and market capitalization of 777,000,000",
  },
  {
    icon: <TokenIcon className="list" />,
    primaryText:
      "Token distribution includes 30% allocation for presale, with 15% for the ICO and 15% for the IEO.",
  },
  {
    icon: <CurrencyExchangeIcon className="list" />,
    primaryText:
      "The presale tokens will be available for purchase at partnered marketplace Coinstore as cryptocurrency exchanges.",
  },
];

export default function TokenICO() {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="text reveal">
              <p className="pre-title">HOMS Token</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                AWU Token ICO
              </Typography>
              <Typography variant="h5" align="start" component="p">
                AWU Token ICO (Initial Coin Offering) and Initial Exchange
                Offering (IEO) are fundraising events for HOMS Tokens that are
                made available for purchase by stake holders and the general
                public.
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    {listItemsData?.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>{item.icon}</ListItemAvatar>
                        <ListItemText>{item.primaryText}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6}>
            <img src={icoImage} alt="Awu Coin" className="ico-image2" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
