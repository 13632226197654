import { Grid, Typography, Container } from "@mui/material/";
import icoImage from "../../assets/images/icoImage.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TokenIcon from "@mui/icons-material/Token";

export default function TermsAndConditions() {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 13, pb: 6 }}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <div className="text reveal">
              <p className="pre-title">AWU Token ICO</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                Terms and Conditions
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <LocationOnIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b>Eligibility Criteria</b>
                        <br></br> a. The HOMS token ICO is open to participants
                        who are at least 18 years of age and reside in ASEAN
                        countries. where the sale and purchase of digital tokens
                        are legal.
                        <br />
                        b. AWU Foundation reserves the right to refuse
                        participation to any individual or entity at its sole
                        discretion.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <AttachMoneyIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Token Sale Details </b>
                        <br></br>a. The HOMS token ICO will begin on August 1,
                        2023 and end on or when the hard cap is reached. <br />{" "}
                        b. The price of each HOMS token will be $0.1, payable in
                        accepted cryptocurrencies or fiat currencies. <br /> c.
                        The accepted payment methods will be crypto or fiat
                        currencies in HOMS Token wallet or with partnered crypto
                        exchange such BLX, CoexStar and X.plus.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <ApartmentIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Token Allocation and Distribution </b>
                        <br></br>
                        a. A total of 15% HOMS tokens will be available for sale
                        during the ICO. <br /> b. HOMS Foundation will
                        distribute the tokens to participants based on the
                        amount of funds they contribute during the ICO. <br />{" "}
                        c. The distribution of HOMS tokens will be completed
                        within 2-3 months after the end of the ICO.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <TokenIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Token Ownership and Transfer </b>
                        <br></br>
                        a. Participants will receive ownership of HOMS tokens
                        after the distribution is complete. <br /> b. HOMS
                        tokens will be transferable after the end of the ICO and
                        once they have been distributed to participants. <br />{" "}
                        c. Participants are responsible for the safekeeping of
                        their HOMS tokens and the security of their
                        cryptocurrency wallets.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Risk Disclosures </b>
                        <br></br>
                        a. Participants in the HOMS token ICO should be aware of
                        the risks associated with investing in digital tokens,
                        which include but are not limited to market volatility,
                        regulatory changes, and hacking risks. <br /> b. AWU
                        Foundation does not guarantee any returns on HOMS token
                        investments and is not responsible for any losses
                        incurred by participants.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Use of Funds </b>
                        <br></br>
                        a. AWU Foundation will use the funds raised during the
                        ICO to develop and expand its affordable rental
                        apartment projects in ASEAN countries. <br /> b. AWU
                        Foundation reserves the right to change the allocation
                        of funds at its sole discretion, based on the needs and
                        priorities of the organization.
                      </ListItemText>
                    </ListItem>
                    <ListItem className="list-content">
                      <ListItemAvatar class="flex items-center content-center gap-3">
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText className="ml-5">
                        <b> Disclaimers </b>
                        <br></br>
                        a. AWU Foundation makes no representations or warranties
                        regarding the accuracy, reliability, or completeness of
                        any information related to the HOMS token ICO. <br /> b.
                        AWU Foundation is not liable for any damages, losses, or
                        expenses arising from participation in the HOMS token
                        ICO or the ownership of HOMS tokens. <br /> c. HOMS
                        Foundation reserves the right to amend these terms and
                        conditions at any time, without prior notice to
                        participants. By participating in the HOMS token ICO,
                        participants agree to these terms and conditions and
                        acknowledge the risks associated with investing in
                        digital tokens.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <div className="text reveal">
              <p className="pre-title">AWU Token IEO</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                Terms and Conditions
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    <ListItem>
                      <ListItemAvatar>
                        <LocationOnIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Eligibility criteria Participants must be at least 18
                        years old and comply with all applicable laws and
                        regulations in their jurisdiction. The AWU Foundation
                        reserves the right to deny participation to anyone who
                        does not meet the eligibility criteria or fails to
                        provide accurate information.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <AttachMoneyIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Token sale details The IEO will take place on a selected
                        cryptocurrency exchange platform. The start and end date
                        of the IEO, the price of the tokens, and the accepted
                        payment methods will be announced on the AWU Foundation
                        website and the participating exchange platform.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <ApartmentIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Token allocation and distribution The AWU Foundation
                        will allocate a fixed number of tokens for the IEO, and
                        the participating exchange platform will handle the
                        distribution of the tokens to participants based on
                        their purchase amount.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <TokenIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Token ownership and transfer Once participants receive
                        their tokens, they will have full ownership and
                        transferability rights over them, subject to applicable
                        laws and regulations.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Risk disclosures The AWU Foundation will provide a
                        detailed explanation of the risks associated with
                        investing in the AWU Token IEO and holding HOMS tokens.
                        Participants should carefully consider these risks
                        before participating in the IEO.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Use of funds The AWU Foundation will detail how the
                        funds raised during the IEO will be used to support the
                        AWU Foundation's mission and initiatives.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Disclaimers The AWU Foundation will include various
                        disclaimers regarding the accuracy of information
                        provided and any liability on the part of the AWU
                        Foundation, the participating exchange platform, and any
                        third-party service providers involved in the IEO.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Anti-money laundering (AML) and Know Your Customer (KYC)
                        requirements Participants may be required to undergo AML
                        and KYC checks as part of the IEO process. The AWU
                        Foundation and the participating exchange platform will
                        comply with all applicable AML and KYC laws and
                        regulations.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Refund policy The AWU Foundation and the participating
                        exchange platform reserve the right to cancel the IEO at
                        any time and refund participants' payments. Participants
                        may also be eligible for refunds under certain
                        circumstances, such as if the AWU Foundation fails to
                        deliver the tokens or the tokens do not meet the
                        specifications outlined in the IEO terms and conditions.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CurrencyExchangeIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Amendments The AWU Foundation reserves the right to
                        amend the IEO terms and conditions at any time and
                        without prior notice. Participants should regularly
                        review the terms and conditions for any updates or
                        changes.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
