import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export default function FutureContainer({ variant, icon, text, title }) {
  const iconPosition = variant === "right" ? "right" : "left";

  return (
    <Box className={`future-box-container ${iconPosition}`}>
      <Box className="icon-container">
        <img src={icon} alt="Icon" />
      </Box>
      <Paper className="text-container">
        <Typography>
          <h3>{title}</h3>
          {text}
        </Typography>
      </Paper>
    </Box>
  );
}
