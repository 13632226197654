import SubHeader from "../../../layouts/global/SubHeader";
import Header from "../../../layouts/global/Header";
import Footer from "../../../layouts/global/Footer";
import Hero from "../Homepage/HomeSections/Hero";
import About from "../Homepage/HomeSections/About";
import MissionFuture from "../Homepage/HomeSections/MissionFuture";
import Board from "../Homepage/HomeSections/Board";
import Future from "../Homepage/HomeSections/Future";
import Newsletter from "../../HomeView/Newsletter";
import Business from "../../HomeView/Homepage/HomeSections/Business";

export default function Homepage() {
  return (
    <div>
      <div className="top homepage top-background">
        <Header />
        <Hero />
      </div>
      <About />
      <MissionFuture />
      <Future />
      <Business />
      <Board />
      {/* <Newsletter /> */}
      <Footer className="footer" />
    </div>
  );
}
