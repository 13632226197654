import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import distribution from "../../assets/images/Distribution.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SquareIcon from "@mui/icons-material/Square";
import awuCoin from "../../assets/images/awuCoin.png";
import { Box } from "@mui/material";
import aboutUs from "../../assets/images/tokens.png";
import {
  CurrencyExchange,
  Handshake,
  House,
  Payments,
  People,
  PriceChange,
  Troubleshoot,
} from "@mui/icons-material";
import BenefitsBox from "../../components/BenefitsBox";
import BannerHeader from "../../layouts/global/BannerHeader";
export default function Token() {
  const [dense] = React.useState(false);
  return (
    <div>
      <BannerHeader
        title="HOMS Token"
        subTitle="Always with us"
        url={aboutUs}
      />

      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="token"
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="text reveal">
              <p className="pre-title">HOMS Token</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                What is HOMS Token?
              </Typography>
              <Typography variant="h5" align="start" component="p">
                The HOMS token is a cryptocurrency designed to serve as the
                currency for all transactions within the HOMS ecosystem. Tenants
                will pay their rent using HOMS tokens, which will represent a
                stake in the appreciation of the token's value over time. The
                HOMS token will be issued on the BNB Smart Chain network and
                will use the proof-of-stake consensus mechanism. The HOMS
                project will consist of various sections, each with distinct
                characteristics such as:
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Low rental apartments for individuals with low income
                        levels.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Fractional ownership of apartments through NFTs.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        A decentralized, community-driven ecosystem that
                        promotes sustainable housing.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>Secured savings</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Transparent, and efficient payment solutions utilizing
                        blockchain technology.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6}>
            <img src={awuCoin} alt="Awu Coin" className="" />
          </Grid>
        </Grid>
      </Container>

      {/* <Container sx={{ pt: 8, pb: 6 }} id="distribution">
        <Grid container spacing={2}>
          <Grid item lg={7}>
            <img src={distribution} alt="Pie chart" />
          </Grid>
          <Grid item lg={5} className="reveal">
            <p className="pre-title">AWU Allocation</p>
            <Typography component="h1" variant="h2" align="start" gutterBottom>
              Distribution
            </Typography>
            <Typography variant="body2" align="start">
              HOMS Token is allocated to different stakeholders within the AWU
              ecosystem through a series of stages, which ensure that HOMS
              tokens are readily available and accessible to users and stake
              holders, thereby supporting the growth and development of the AWU.
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <List dense={dense}>
                  <ListItem>
                    <ListItemAvatar>
                      <SquareIcon className="dark--40" />
                    </ListItemAvatar>
                    <ListItemText>
                      <p className="dist-percentage ">40%</p>
                      <p className="dist-text">Foundation Safe Keeping</p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <SquareIcon className="dark--30" />
                    </ListItemAvatar>
                    <ListItemText>
                      <p className="dist-percentage">30%</p>
                      <p className="dist-text">ICO & IEO</p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <SquareIcon className="dark--15" />
                    </ListItemAvatar>
                    <ListItemText>
                      <p className="dist-percentage">15%</p>
                      <p className="dist-text">
                        Corporate Social Responsibility/ NGO
                      </p>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <SquareIcon className="light--15" />
                    </ListItemAvatar>
                    <ListItemText>
                      <p className="dist-percentage">15%</p>
                      <p className="dist-text">Founder Share</p>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}

      <Container sx={{ pt: 8, pb: 6 }}>
        <Typography className="pre-title-center">Our Foundation</Typography>
        <Typography
          align="center"
          variant="h2"
          style={{
            marginBottom: "5rem",
          }}
        >
          Benefits of HOMS Token
        </Typography>

        <Grid container spacing={0}>
          <Grid xs={12} lg={6}>
            <Box id="left-benefits-box">
              <Typography variant="h1">Tenants</Typography>
              <Grid container direction={`column`} rowGap={5}>
                <Grid item>
                  <BenefitsBox
                    title={`Affordable housing`}
                    body={`Tenants who pay rent in HOMS tokens will have access to affordable housing, with rents set at 50% of the local market rate.`}
                    icon={House}
                  />
                </Grid>
                <Grid item>
                  <BenefitsBox
                    title={`Participation in token appreciation`}
                    body={`Tenants who pay rent in HOMS tokens will have the opportunity to participate in the appreciation of the token, which is expected to increase in value over time as the demand for HOMS tokens rises.`}
                    icon={Handshake}
                  />
                </Grid>
                <Grid item>
                  <BenefitsBox
                    title={`Seamless payment experience`}
                    body={`By using the BNB Smart Chain network, the HOMS token offers a more accessible, secure, and efficient payment solution for tenants, providing a seamless experience within the AWU ecosystem.`}
                    icon={CurrencyExchange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box id="right-benefits-box">
              <Typography
                variant="h1"
                sx={{
                  textAlign: {
                    sx: "start",
                    md: "end",
                  },
                }}
              >
                Foundation
              </Typography>
              <Grid container direction={`column`} rowGap={5}>
                <Grid item>
                  <BenefitsBox
                    title={`Fundraising`}
                    body={`The foundation can use HOMS tokens as a way to raise funds for their initiatives and programs.`}
                    direction={`left`}
                    icon={PriceChange}
                  />
                </Grid>
                <Grid item>
                  <BenefitsBox
                    title={`Transparency & Efficiency`}
                    body={`The blockchain technology behind HOMS tokens can ease the transaction to be transparent and reduce cost.`}
                    direction={`left`}
                    icon={Troubleshoot}
                  />
                </Grid>
                <Grid item>
                  <BenefitsBox
                    title={`Community engagement`}
                    body={`The use of HOMS tokens can help the foundation engage with its community and supporters, creating a more active and participatory ecosystem around its cause. `}
                    direction={`left`}
                    icon={People}
                  />
                </Grid>
                <Grid item>
                  <BenefitsBox
                    title={`Liquidity`}
                    body={`As HOMS tokens are listed on cryptocurrency exchanges, they can potentially provide liquidity for the foundation's fundraising efforts, making it easier to convert tokens into fiat currency if needed `}
                    direction={`left`}
                    icon={Payments}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
