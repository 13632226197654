import Awu from "../../assets/images/AWU.png";
import { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  styled,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// Style for step labels in mobile view
const MobileStepLabel = styled(StepLabel)({
  fontSize: "1rem",
  fontWeight: "normal",
  padding: 0,
});

export default function BuyAWU() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Register for the Whitelist of AWU's ICO",
    "Complete KYC/AML Verification",
    "Wait for Approval of KYC",
    "Purchase HOMS Tokens",
    "Store your Tokens in a Secure Cryptocurrency Wallet",
  ];

  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div className="reveal">
      <p className="preTitle-issuance">ICO</p>
      <Typography
        component="h1"
        variant="h2"
        className="ico-title"
        gutterBottom
      >
        Steps to Participate <br />
        in AWU Token ICO
      </Typography>

      <Typography variant="h5" component="p" className="ico-description">
        The funds raised from the AWU Token ICO and IEO
        <br />
        will be used to build a condominium project comprising 1000
        <br />
        units. To acquire the AWU Token, stake holders must follow the steps
        below:
      </Typography>

      {isMobile ? (
        <Box sx={{ pt: 2 }}>
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            sx={{ padding: 0 }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <MobileStepLabel>
                  <Typography variant="h6">{label}</Typography>
                </MobileStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      ) : (
        <Grid container>
          <Box sx={{ pt: 8, pb: 6 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx>
              {steps.map((label, index) => (
                <Step key={index}>
                  <MobileStepLabel
                    onClick={handleStep(index)}
                    className="ico-buy-steps"
                  >
                    <Typography className="ico-buy-labels" variant="p">
                      {label}
                    </Typography>
                  </MobileStepLabel>
                </Step>
              ))}
            </Stepper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <ExitToAppIcon
                className={`ico-steps-list icon-fade-in ${
                  activeStep >= 0 && "active"
                }`}
              />
              <FingerprintIcon
                className={`ico-steps-list icon-fade-in ${
                  activeStep >= 1 && "active"
                }`}
              />
              <HourglassBottomIcon
                className={`ico-steps-list icon-fade-in ${
                  activeStep >= 2 && "active"
                }`}
              />
              <img
                src={Awu}
                className={`ico-steps-list icon-fade-in ${
                  activeStep >= 3 && "active"
                }`}
                alt="awuCoin"
              />
              <AccountBalanceWalletIcon
                className={`ico-steps-list icon-fade-in ${
                  activeStep >= 4 && "active"
                }`}
              />
            </Box>
          </Box>
        </Grid>
      )}
    </div>
  );
}
