import React, { useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import buildingImg from "../../../../assets/images/country.png";
import familyImage from "../../../../assets/images/family2.jpg";
import mapBackground from "../../../../assets/images/map.png"; // Import the map.png image
import "./Future.css";

export default function About() {
  const reveals = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      reveals.current.forEach((element) => {
        if (!element) return; // If the element is null or undefined, skip it
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight * 0.85) {
          element.classList.add("active");
        } else {
          element.classList.remove("active"); // Optional: Remove the class when not in view
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Run on mount in case elements are already in view

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container sx={{ paddingTop: "150px" }}>
      <Grid
        container
        className="about-section-unique"
        style={{ backgroundImage: `url(${mapBackground})` }}
      >
        <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
          <div
            className="image-container-unique reveal-unique"
            ref={(el) => (reveals.current[0] = el)}
          >
            <img
              src={familyImage}
              alt="Family Image"
              className="future-image-unique"
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
          <div
            className="reveal-unique"
            ref={(el) => (reveals.current[1] = el)}
          >
            <Typography className="pre-title">Future of AWU</Typography>
            <Typography component="h1" variant="h2" align="start" gutterBottom>
              Future of AWU
            </Typography>
            <Typography component="p" sx={{ pb: "10px" }}>
              The AWU Foundation will begin by focusing on the provision of
              rental housing and subsequently expand its initiatives to
              encompass comprehensive welfare projects. These projects will
              include financial services, shopping services, scholarships, and
              medical assistance, specifically targeting individuals who are
              marginalized by the existing financial system due to their
              socio-economic status.
            </Typography>
            <Typography component="p" sx={{ pb: "10px" }}>
              It is important to emphasize that the AWU Foundation is not owned
              by any particular minority group; it is a shared asset that
              belongs to all of humanity. The foundation will be managed and
              operated in a highly democratic manner, with a commitment to
              diversify and broaden the composition of its board. In the future,
              the AWU Foundation will steadfastly pursue its mission to promote
              the happiness of the human community.
            </Typography>
            <Typography component="p">
              It will persist across generations with the unwavering slogan of
              "Always with us," ensuring its continuous dedication to serving
              the well-being of all.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
