import {
  Grid,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material/";
import icoImage from "../../assets/images/icoImage.png";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TokenIcon from "@mui/icons-material/Token";

export default function PrivacyPolicy() {
  const listItems = [
    {
      icon: <LocationOnIcon className="list" />,
      text: "Personal Information We Collect We may collect the following types of personal information: Contact Information: name, email address, phone number, and other contact details. Payment Information: billing address, credit card number, and other payment information. Transaction Information: details about transactions made with AWU token and NFT. Usage Information: information about how you use our website and services. Other Information: information you provide to us or that we collect through our website, social media channels, or other means.",
    },
    {
      icon: <AttachMoneyIcon className="list" />,
      text: "How We Use Personal Information We may use personal information for the following purposes: To provide, maintain, and improve our services, including the AWU token and NFT. To process payments and transactions. To communicate with you about our products, services, and promotions. To provide customer support and respond to inquiries. To comply with legal obligations or protect our rights.",
    },
    {
      icon: <ApartmentIcon className="list" />,
      text: "How We Share Personal Information We may share personal information with third-party service providers who perform services on our behalf, such as payment processors, customer support providers, and analytics providers. We may also share personal information with law enforcement, regulatory authorities, or other third parties as required by law or to protect our rights.",
    },
    {
      icon: <TokenIcon className="list" />,
      text: "Your Choices and Rights You may have certain choices and rights regarding the personal information we collect and use, including the right to access, correct, or delete personal information. To exercise these rights, please contact us using the information provided below.",
    },
    {
      icon: <CurrencyExchangeIcon className="list" />,
      text: "Data Security We implement reasonable measures to protect personal information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.",
    },
    {
      icon: <CurrencyExchangeIcon className="list" />,
      text: "Changes to this Privacy Policy We may update this privacy policy from time to time. The latest version of the policy will be posted on our website with a new effective date.",
    },
    {
      icon: <CurrencyExchangeIcon className="list" />,
      text: "Contact Us If you have any questions, concerns, or requests regarding this privacy policy or the handling of personal information, please contact us at: Address: 9 Straits View #06-07, Marina One West Tower, Singapore 018937 Email: community@awufd.com",
    },
  ];
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 14, pb: 6 }}
      >
        <p className="pre-title">AWU's</p>
        <Typography component="h1" variant="h2" align="start" gutterBottom>
          Privacy Policy
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="text reveal">
              {listItems.map((item, index) => (
                <List key={index} sx={{ mb: 2 }}>
                  <ListItem disableGutters>
                    <ListItemAvatar>{item.icon}</ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6">{item.text}</Typography>
                      }
                    />
                  </ListItem>
                </List>
              ))}
            </div>
          </Grid>
          <Grid item lg={6}>
            <img src={icoImage} alt="AWU token" className="img reveal" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
