import React from "react";
import { Container, Typography } from "@mui/material";
import aboutHero from "../../assets/images/aboutUs.jpg";
import innerwave from "../../assets/images/Inner-Wave.png";

function BannerHeader({ title, subTitle, url }) {
  return (
    <div
      className="section inner_hero"
      style={{ backgroundImage: `url(${url})` }}
    >
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          pt: {
            xs: 8,
            md: 8,
          },
          pb: {
            xs: 8,
            md: 10,
          },
        }}
      >
        <div className="text animated animatedFadeInUp fadeInUp">
          <p className="pre-title ">{subTitle}</p>
          <Typography
            component="h1"
            variant="h2"
            align="left"
            gutterBottom
            className="hero-headings"
          >
            {title}
          </Typography>
        </div>
      </Container>
      <img className="inner-wave" src={innerwave} alt="Wave" />
    </div>
  );
}

export default BannerHeader;
