import { Grid, Typography, Container } from "@mui/material/";
import icoImage from "../../assets/images/icoImage.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TokenIcon from "@mui/icons-material/Token";

const faqs = [
  {
    question: "What is the AWU Foundation?",
    answer:
      "The AWU Foundation is a non-profit organization that aims to provide affordable housing for the poor in ASEAN countries. It has introduced innovative solutions like AWU token and NFTs to achieve its mission.",
    icon: <LocationOnIcon className="list" />,
  },
  {
    question: "What is the AWU Token?",
    answer:
      "The  is a cryptocurrency designed to be used within the AWU ecosystem for all transactions, including rent payments. By paying rent in HOMS tokens, tenants can participate in the appreciation of the token's value over time, while the foundation reinvests the rental income into expanding the supply of affordable housing.",
    icon: <AttachMoneyIcon className="list" />,
  },
  {
    question: "How can I buy HOMS Tokens?",
    answer:
      "HOMS Tokens are available for purchase on cryptocurrency exchanges that support its trading such as BLX, CoexStar and X.plus crypto exchange.",
    icon: <ApartmentIcon className="list" />,
  },
  {
    question: "Can I use HOMS tokens for anything other than rent payments?",
    answer:
      "At present, HOMS tokens are designed to be used exclusively within the AWU ecosystem for rent payments and related transactions. However, the foundation may explore additional use cases for the token in the future such as for education, shopping, and financial services.",
    icon: <TokenIcon className="list" />,
  },
  {
    question: "What are NFTs and how are they related to AWU Foundation?",
    answer:
      "NFTs (Non-Fungible Tokens) are unique digital assets that represent ownership of a particular item. The AWU Foundation has introduced NFTs as a way to raise funds for its affordable housing projects. By purchasing an AWU NFT, investors can own a fractional ownership of the condo property while also supporting the foundation's mission.",
    icon: <CurrencyExchangeIcon className="list" />,
  },
  {
    question: "How can I participate in the AWU Foundation's mission?",
    answer:
      "You can participate in the AWU Foundation's mission by buying HOMS Tokens, purchasing AWU NFTs, or making a donation directly to the foundation. By doing so, you can invest in real estate while also making a positive social impact.",
    icon: <CurrencyExchangeIcon className="list" />,
  },
  {
    question: "Is the AWU Foundation a legitimate organization?",
    answer:
      "Yes, the AWU Foundation is a legitimate non-profit organization registered in Singapore. It is dedicated to providing affordable housing for the poor in ASEAN countries and has introduced innovative solutions like AWU token and NFTs to achieve its mission.",
    icon: <CurrencyExchangeIcon className="list" />,
  },
];

export default function FAQs() {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="text reveal">
              <p className="pre-title">AWU FOUNDATION</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                Frequently Asked Questions
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    {faqs.map((faq) => (
                      <ListItem key={faq.question}>
                        <ListItemAvatar>{faq.icon}</ListItemAvatar>
                        <ListItemText>
                          {faq.question}
                          A: {faq.answer}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={6}>
            <img src={icoImage} alt="Awu Coin" className="ico-image2" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
