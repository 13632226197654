import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import family from "../../assets/images/family.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import BannerHeader from "../../layouts/global/BannerHeader";
import FutureOfAWU from "../sections/FutureOfAWU";
import Future from "../sections/Future";
import rental from "../../assets/images/rental.png";
import financial from "../../assets/images/financial-statement.png";
import scholarship from "../../assets/images/scholarship.png";
import shopping from "../../assets/images/worldwide-shipping.png";
import aboutUs from "../../assets/images/aboutUs.jpg";

export default function About() {
  return (
    <div>
      <BannerHeader
        title="Who is AWU?"
        subTitle="Always with us"
        url={aboutUs}
      />

      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="about"
      >
        <Grid container spacing={5}>
          <Grid item lg={6} order={{ xs: 1, sm: 1, md: 0 }}>
            <img src={family} alt="Family" />
          </Grid>
          <Grid item lg={6} order={{ xs: 0, sm: 0, md: 1 }}>
            <div>
              <p className="pre-title">Who are we?</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                AWU Foundation
              </Typography>
              <Typography variant="h5" align="start" component="p">
                The AWU Foundation was established in Singapore in 2023 with the
                aim of providing housing for the poor in countries with a wide
                gap between rich and poor. It means that it will always be with
                the poor in society and will create happiness and hope by
                creating a stable home for the poor.
              </Typography>
              <div className="text-box">
                <div className="text-count">50%</div>
                <div className="text-description">
                  <p>
                    There are many countries where the poor are more than 50% of
                    the total population.
                  </p>
                  <a
                    className="text-link"
                    target="_blank"
                    href="https://www.youtube.com/@AWUFoundation"
                  >
                    Watch our videos here
                    <ArrowRightAltIcon />
                  </a>
                </div>
              </div>
              <Typography variant="h5" align="start" component="p">
                The AWU Foundation will enable them to adapt stably to society
                by providing a place of life for families who are poor and
                unable to secure living space.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* <Future /> */}
      <div class="programs">
        <Grid container spacing={1}>
          <Grid item lg={4} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="text-box main">
              <h2>Initiatives and Programs</h2>
              <div className="text-description">
                <p>
                  There are many countries where the poor are more than 50% of
                  the total population.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={2} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="text-box">
              <img src={rental} alt="Pie chart" />
              <h3>Rental apartment</h3>
              <div className="text-description">
                <p>
                  The foundation will provides very affordable rental apartments
                  to lower-income groups at 50% of the local market rate. This
                  program helps to address the issue of homelessness and
                  provides access to safe and adequate housing to those who need
                  it the most.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={2} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="text-box">
              <img src={financial} alt="Pie chart" />
              <h3>Financial services </h3>
              <div className="text-description">
                <p>
                  The foundation will also offers financial services such as
                  microloans, savings accounts, and financial literacy programs
                  to help tenants improve their financial stability and build a
                  better future.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={2} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="text-box">
              <img src={scholarship} alt="Pie chart" />
              <h3>Scholarship program</h3>
              <div className="text-description">
                <p>
                  The foundation will provide scholarship programs to
                  lower-income students to help them pursue higher education and
                  achieve their full potential.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={2} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="text-box">
              <img src={shopping} alt="Pie chart" />
              <h3>Shopping services</h3>
              <div className="text-description">
                <p>
                  The foundation will partner with local businesses to provide
                  tenants with access to affordable and quality goods and
                  services. This program supports local businesses while also
                  improving the standard of living for tenants.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Future />
      <FutureOfAWU />
    </div>
  );
}
