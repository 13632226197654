import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import family from "../../../../assets/images/family.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import "./About.css";

export default function About() {
  return (
    <Container
      disableGutters
      maxWidth="lg"
      component="main"
      sx={{ pt: 8, pb: 6 }}
      id="about"
      style={{ marginTop: "180px" }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
        }}
      >
        <Grid item lg={7} order={{ xs: 1, sm: 1, md: 0 }}>
          <img
            src={family}
            alt="Family"
            className="about-image"
            style={{ width: "1500px" }}
          />
        </Grid>
        <Grid
          item
          lg={5}
          order={{ xs: 0, sm: 0, md: 1 }}
          style={{
            zIndex: 2,
          }}
        >
          <div className="text reveal">
            <p className="pre-title">AWU FOUNDATION</p>
            <Typography component="h1" variant="h2" align="start" gutterBottom>
              Purpose of Establishing the AWU Foundation
            </Typography>
            <Typography variant="h5" align="start" component="p">
              The AWU Foundation was created in to help the poor in countries
              where there is a large difference between the rich and the poor.
              It aims to provide housing for those in need. Housing, along with
              food and clothing, is essential for people to survive. The issue
              of housing is particularly challenging for the poor. The name AWU
              stands for 'Always With Us' which means that the foundation will
              always support and be there for the poor in society. Its goal is
              to bring happiness and hope by creating secure and stable homes
              for those who are less fortunate.
            </Typography>
            <div className="button-group">
              <Link
                component={RouterLink}
                to="/About"
                variant="button outlined"
                color="text.primary"
                sx={{ my: 1, mx: 1.5 }}
                className="button button-primary"
              >
                Read More
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
