import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import awuCoin from "../../../../assets/images/awuCoin.png";
import NFT from "./NFT.js";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export default function Token() {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="token"
      >
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="text reveal">
              <p className="pre-title">HOMS Token</p>
              <Typography
                component="h1"
                variant="h2"
                align="start"
                gutterBottom
              >
                What is HOMS Token?
              </Typography>
              <Typography variant="h5" align="start" component="p">
                The HOMS token is a cryptocurrency designed to serve as the
                currency for all transactions within the AWU ecosystem. Tenants
                will pay their rent using HOMS tokens, which will represent a
                stake in the appreciation of the token's value over time. The
                HOMS token will be issued on the BLOX chain network and will use
                the proof-of-stake consensus mechanism. The AWU project will
                consist of various sections, each with distinct characteristics
                such as:
              </Typography>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <List dense={false}>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Low rental apartments for individuals with low income
                        levels.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Fractional ownership of apartments through NFTs.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        A decentralized, community-driven ecosystem that
                        promotes sustainable housing.
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>Secured savings</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <CheckCircleIcon className="list" />
                      </ListItemAvatar>
                      <ListItemText>
                        Transparent, and efficient payment solutions utilizing
                        blockchain technology.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <div className="button-group">
                <Link
                  component={RouterLink}
                  to="/Token"
                  variant="button outlined"
                  color="text.primary"
                  sx={{ my: 1, mx: 1.5 }}
                  className="button button-primary"
                >
                  Read More
                </Link>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{ display: "flex", alignItems: "center", marginLeft: "-20px" }}
          >
            <img src={awuCoin} alt="Awu Coin" className="token-image reveal" />
          </Grid>
        </Grid>
      </Container>
      <NFT />
    </div>
  );
}
