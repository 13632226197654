import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import "./Hero.css";
import LogoImage from "../../../../assets/images/AWU.png"; // Import your logo image
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTelegram,
  FaTiktok,
  FaYoutube,
  FaMailchimp,
  FaVoicemail,
  FaEnvelope,
} from "react-icons/fa"; // Import social icons
import { MdKeyboardArrowDown } from "react-icons/md"; // Import arrow down icon

export default function Home() {
  const handleScroll = () => {
    const aboutSection = document.getElementById("about");
    aboutSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="section hero">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          position: "relative", // Position container relative
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundPosition: "0px 50px, 100%",
          backgroundSize: "100%, cover",
          backgroundRepeat: "no-repeat",
        }}
        className="hero"
        id="hero"
      >
        <div className="color-overlay"></div>
        <img
          src={LogoImage}
          alt="Logo"
          className="logo"
          style={{ maxWidth: "150px", marginBottom: "20px" }}
        />{" "}
        {/* Add your logo image */}
        <div className="text animated animatedFadeInUp fadeInUp">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            gutterBottom
            className="hero-headings"
          >
            <span
              style={{
                color: "#e99c31",
                fontWeight: "bold",
                fontSize: "4rem",
                display: "block",
                textStroke: ".1px white",
                position: "relative",
              }}
            >
              AWU FOUNDATION
            </span>{" "}
            <span
              style={{
                color: "#fff",
                marginTop: "0rem",
                fontWeight: "bolder",
                fontSize: "3rem",
                display: "block",
                marginBottom: ".5rem",
              }}
            >
              Always With Us
            </span>{" "}
            <div className="social-icons">
              <a href="mailto:awufd@homsworld.org">
                <FaEnvelope className="social-icon" />
              </a>
              <a href="https://www.facebook.com/awufoundation">
                <FaFacebook className="social-icon" />
              </a>
              <a href="https://twitter.com/awu_foundation">
                <FaTwitter className="social-icon" />
              </a>
              <a href="https://www.instagram.com/awu.foundation/">
                <FaInstagram className="social-icon" />
              </a>
              <a href="https://www.youtube.com/@AWUFoundation">
                <FaYoutube className="social-icon" />
              </a>
              <a href="https://www.tiktok.com/@awufoundation?lang=en">
                <FaTiktok className="social-icon" />
              </a>
              <a href="https://t.me/awufd">
                <FaTelegram className="social-icon" />
              </a>
            </div>
            <div className="arrow-down" onClick={handleScroll}>
              <MdKeyboardArrowDown />
              <span>LEARN MORE</span>
            </div>
          </Typography>
        </div>
      </Container>
    </div>
  );
}
