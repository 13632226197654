import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import buildingImg from "../../../../assets/images/country.png";
import Token from "../../Homepage/HomeSections/Token";
import Chairmain from "../../../../assets/images/board_members/chairmanpic.png";

const directors = [
  {
    name: "Seungchan Lee",
    description: "President",
    image: Chairmain,
  },
  // Add more directors as needed
];

export default function Timeline() {
  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="directors"
      >
        <div className="reveal">
          <div className="text text-center">
            <p className="pre-title-center">Always With Us</p>
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              AWU Foundation President
            </Typography>
            <Typography variant="h5" align="center" component="p">
              The AWU Foundation employs a decentralized management approach
              that utilizes a board of directors consisting of 10 to 30 members.
              This board includes Non-Government Organizations (NGOs) from five
              or more countries or a reputable social organization.
            </Typography>
          </div>
        </div>
        <Grid container spacing={2} justifyContent="center" mt={4}>
          {directors.map((director, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <DirectorCard {...director} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Token />
    </div>
  );
}

const DirectorCard = ({ name, description, image }) => (
  <div className="reveal" style={{ textAlign: "center" }}>
    <Avatar
      alt={name}
      src={image}
      sx={{ width: 300, height: 300, margin: "0 auto", borderRadius: "5%" }}
    />
    <Typography variant="h6" mt={2}>
      {name}
    </Typography>
    <Typography variant="body1">{description}</Typography>
  </div>
);
