import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import foundationSelected from "../../../../assets/images/yellow icons/foundation-selected.svg";
import buildSelected from "../../../../assets/images/yellow icons/build-selected.svg";
import buySelected from "../../../../assets/images/yellow icons/buy-selected.svg";
import sellSelected from "../../../../assets/images/yellow icons/sell-selected.svg";
import leaseSelected from "../../../../assets/images/yellow icons/lease-selectedsvg.svg";
import checkoutSelected from "../../../../assets/images/yellow icons/checkout-selected.svg";
import returnSelected from "../../../../assets/images/yellow icons/return-deposit-selected.svg";
import utilizeSelected from "../../../../assets/images/yellow icons/utilize-revenue-selected.svg";
import foundation from "../../../../assets/images/gray icons/foundation.svg";
import build from "../../../../assets/images/gray icons/foundation.svg";
import buy from "../../../../assets/images/gray icons/buy.svg";
import sell from "../../../../assets/images/gray icons/sell.svg";
import lease from "../../../../assets/images/gray icons/lease.svg";
import checkout from "../../../../assets/images/gray icons/checkout.svg";
import returnDeposit from "../../../../assets/images/gray icons/return_deposit.svg";
import utilize from "../../../../assets/images/gray icons/utilize_revenue.svg";
import jQuery from "jquery";

var $ = jQuery;

export default function Cycle() {
  React.useEffect(() => {
    function autoHeightCircle() {
      var circle = $(".circle--rotate"),
        circleInner = $(".animate-wrapper"),
        circleH = circle.width(),
        circleInnerH = circleInner.width();
      circle.height(circleH);
      circleInner.height(circleInnerH);
    }
    $("#circle--rotate").circle();
    autoHeightCircle();

    $(window).resize(function () {
      autoHeightCircle();
    });

    setInterval(function () {
      $(".circle--slider .next").click();
    }, 7000);
  }, []);

  return (
    <div className="section cycle">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
        id="cycle"
      >
        <div className="text text-center ">
          <p className="pre-title-center">What we have for you</p>
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            Philanthropic Cycle
          </Typography>
          <Typography variant="h5" align="center" component="p">
            This cycle utilizes cryptocurrency to fund the development of a real
            estate project while also supporting philanthropic causes. Stake
            holders have the potential to benefit financially from the success
            of the project, and the use of AWU Token as a payment method for
            leasing or rentals could create additional demand for the
            cryptocurrency.
          </Typography>
        </div>
        <section id="izz0dg" className="services">
          <div className="circle--slider" id="ign6c">
            <div className="rotate--circle" id="i3mvn">
              <ul id="circle--rotate" className="circle--rotate">
                <li className="block" id="i3jgx">
                  <div className="icon" id="i7yvi">
                    <img
                      src={foundationSelected}
                      className="icon-title"
                      id="in67k"
                      alt="AWU Foundation"
                    />
                    <div className="icon-div">
                      <img
                        src={foundation}
                        className="icon-circle"
                        id="ixots"
                        alt="Foundation"
                      />
                    </div>
                    <p className="title">Foundation</p>
                    <span id="ixg5i"> </span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={buildSelected}
                      className="icon-title mx-auto"
                      id="i8sp3"
                      alt=""
                    />
                    <div className="icon-div">
                      {" "}
                      <img src={build} className="icon-circle" alt="" />{" "}
                    </div>
                    <p className="title">Build</p>
                    <span> </span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={buySelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      {" "}
                      <img src={buy} className="icon-circle" alt="" />{" "}
                    </div>
                    <p className="title">Buy</p>
                    <span> </span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={sellSelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      <img src={sell} className="icon-circle" alt="" />
                    </div>
                    <p className="title">Sell</p>
                    <span></span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={leaseSelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      {" "}
                      <img src={lease} className="icon-circle" alt="" />
                    </div>
                    <p className="title">Lease</p>
                    <span> </span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={checkoutSelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      <img src={checkout} className="icon-circle" alt="" />
                    </div>
                    <p className="title">Client Checkout</p>
                    <span></span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={returnSelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      <img src={returnDeposit} className="icon-circle" alt="" />
                    </div>
                    <p className="title">Return/Deposit</p>
                    <span></span>
                  </div>
                </li>
                <li className="block">
                  <div className="icon">
                    <img
                      src={utilizeSelected}
                      className="icon-title mx-auto"
                      alt=""
                    />
                    <div className="icon-div">
                      {" "}
                      <img src={utilize} className="icon-circle" alt="" />
                    </div>
                    <p className="title">Utilize Revenue</p>
                    <span></span>
                  </div>
                </li>
              </ul>
              <div className="animate-wrapper">
                <div className="animate" id="isa64v">
                  <div className="animate-img" id="ivwfke">
                    <div>
                      <div className="animate-more" id="izw1ul">
                        <div className="animate-title" id="i91f7h">
                          <h4 className="main-title">Foundation</h4>
                        </div>
                        <p className="desc">
                          Established as a non-profit organization that supports
                          sustainable housing initiatives, financial and social
                          welfare opportunities, and community-driven
                          ecosystems.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Build</h4>
                        </div>
                        <p className="desc">
                          Construct condominium that provides affordable rental
                          apartments for low-income families in ASEAN countries.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Buy</h4>
                        </div>
                        <p className="desc">
                          Once the apartments are built, clients can purchase
                          them at an affordable price set by the foundation
                          using HOMS Token or bought a fractional ownership.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Sell</h4>
                        </div>
                        <p className="desc">
                          Clients can also choose to sell their apartments to
                          other clients through crypto exchange.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Lease</h4>
                        </div>
                        <p className="desc">
                          Clients can lease the apartments as well, and the
                          revenue from rent is utilized to cover operational
                          expenses, maintain the apartments, and fund other
                          foundation initiatives.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Client Check out</h4>
                        </div>
                        <p className="desc">
                          The foundation conducts client checkouts to ensure
                          that clients are satisfied with the apartments and
                          have all their needs met.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Return/Deposit</h4>
                        </div>
                        <p className="desc">
                          {" "}
                          At the end of the lease, clients can choose to renew
                          their lease, return the apartment, or deposit an
                          amount to eventually own the apartment.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="animate">
                  <div className="animate-img">
                    <div>
                      <div className="animate-more">
                        <div className="animate-title">
                          <h4 className="main-title">Utilize Revenue</h4>
                        </div>
                        <p className="desc">
                          The revenue collected from rent is utilized to cover
                          operational expenses, maintain the apartments, and
                          fund other foundation initiatives, with the goal
                          philanthropic purposes.
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prev"></div>
              <div className="next"></div>
            </div>
          </div>
        </section>

        <section className="service--mobile">
          <div className="icon-list">
            <div className="icon">
              <img src={foundationSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Foundation</h4>
              <p className="icon-desc">
                The foundation conducts client checkouts to ensure that clients
                are satisfied with the apartments and have all their needs met.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={buildSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Build</h4>
              <p className="icon-desc">
                Construct condominium that provides affordable rental apartments
                for low-income families in ASEAN countries.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={buySelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Buy</h4>
              <p className="icon-desc">
                Once the apartments are built, clients can purchase them at an
                affordable price set by the foundation using AWU Token or bought
                a fractional ownership.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={sellSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Sell</h4>
              <p className="icon-desc">
                Clients can also choose to sell their apartments to other
                clients through crypto exchange.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={leaseSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Lease</h4>
              <p className="icon-desc">
                Clients can lease the apartments as well, and the revenue from
                rent is utilized to cover operational expenses, maintain the
                apartments, and fund other foundation initiatives.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={checkoutSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Client Checkout</h4>
              <p className="icon-desc">
                The foundation conducts client checkouts to ensure that clients
                are satisfied with the apartments and have all their needs met.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={returnSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Return/Deposit</h4>
              <p className="icon-desc">
                At the end of the lease, clients can choose to renew their
                lease, return the apartment, or deposit an amount to eventually
                own the apartment.
              </p>
            </div>
          </div>
          <div className="icon-list">
            <div className="icon">
              <img src={utilizeSelected} className="icon-img" alt="" />
            </div>
            <div className="icon-desc">
              <h4 className="title">Utilize Revenue</h4>
              <p className="icon-desc">
                The revenue collected from rent is utilized to cover operational
                expenses, maintain the apartments, and fund other foundation
                initiatives, with the goal philanthropic purposes.
              </p>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}
